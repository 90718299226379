import React from "react";
import PropTypes from "prop-types";
import Image from "../../elements/Image";
import ScrollAnimation from "react-animate-on-scroll";

// Animate.css
import "animate.css/animate.min.css";

const propTypes = {
	key: PropTypes.number.isRequired,
	image: PropTypes.string,
	name: PropTypes.string.isRequired,
	number: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	offset: PropTypes.string.isRequired,
	layout: PropTypes.string.isRequired,
};

const defaultProps = {
	key: 0,
	image: "",
	name: "",
	number: "",
	text: "",
	offset: "0",
	layout: "col-lg-4 col-md-6",
};

const Perk = ({ className, ...props }) => {
	return (
		<div className={props.layout}>
			<ScrollAnimation animateIn="bounceInUp" duration={2} animateOnce={true}>
				<div className={`section-perks__box ${className}`} style={{ marginTop: props.offset }}>
					<div className="section-perks__box__content">
						<div className="section-perks__box__content--number">{props.number}</div>
						<h4>{props.name}</h4>
						<p>{props.text}</p>
					</div>
					{props.image && (
						<div className="section-perks__box--img">
							<Image src={props.image} alt="Avatar" />
						</div>
					)}
				</div>
			</ScrollAnimation>
		</div>
	);
};

Perk.propTypes = propTypes;
Perk.defaultProps = defaultProps;

export default Perk;
