import React from "react";
import Perk from "./partials/Perk";

// Images
import perk1 from "../../assets/images/img-perk-1.png";
import perk3 from "../../assets/images/img-perk-3.png";
import perk6 from "../../assets/images/img-perk-6.png";

const Perks = () => {
	const perksDataStatic = [
		{
			number: "01.",
			name: "30 days’ holiday",
			text: "(plus bank holidays on top)",
			image: perk1,
			offset: "0",
		},
		{
			number: "02.",
			name: "Equipment allowance",
			text: "(enough to cover a new MacBook Pro and accessories)",
			offset: "11rem",
		},
		{
			number: "03.",
			name: "Health insurance",
			text: "(a generous policy with loads of perks)",
			image: perk3,
			offset: "5.75rem",
		},
		{
			number: "04.",
			name: "Life assurance",
			text: "(four times your annual salary)",
			offset: "0",
			layout: "offset-md-1 col-md-5",
		},
		{
			number: "05.",
			name: "Regular social events",
			text: "(we pick up the tab at our monthly get together)",
			offset: "8rem",
			layout: "col-md-5",
		},
		{
			number: "06.",
			name: "Conferences & training",
			text: "(so you can keep pace in this fast moving world)",
			image: perk6,
			offset: "-3rem",
			layout: "col-lg-12 col-md-6",
		},
	];

	return (
		<section className="section-perks">
			<div className="container">
				<h2>The orange jellyfish perks</h2>
				<div className="row">
					{perksDataStatic.map((perk, key) => {
						return <Perk key={key} className={`box-${key + 1}`} number={perk.number} name={perk.name} text={perk.text} image={perk.image} offset={perk.offset} layout={perk.layout} />;
					})}
				</div>
			</div>
		</section>
	);
};

export default Perks;
