import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const propTypes = {
	key: PropTypes.number.isRequired,
	image: PropTypes.string,
	name: PropTypes.string.isRequired,
	number: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	offset: PropTypes.string.isRequired,
};

const defaultProps = {
	key: 0,
	image: "",
	name: "",
	number: "",
	text: "",
	offset: "0",
};

const Role = ({ ...props }) => {
	return (
		<a href={props.link}>
			<div className="section-roles__boxes__box">
				<p>{props.text}</p>
				<h3>{props.title}</h3>
			</div>
		</a>
	);
};

Role.propTypes = propTypes;
Role.defaultProps = defaultProps;

export default Role;
