import React from "react";
import Role from "./partials/Role";

const Roles = ({ id }) => {
	const rolesDataStatic = [
		{
			title: "Senior software engineer",
			text: "Remote, UK Based  •  Engineering",
			link: "mailto:hello@orangejellyfish.com",
		},
		{
			title: "Software engineer",
			text: "Remote, UK Based  •  Engineering",
			link: "mailto:hello@orangejellyfish.com",
		},
	];

	return (
		<section className="section-roles" id={id}>
			<div className="container">
				<h2>Open roles</h2>
				<div className="section-roles__boxes">
					{rolesDataStatic.map((role, key) => {
						return <Role key={key} title={role.title} text={role.text} link={role.link} />;
					})}
				</div>
			</div>
		</section>
	);
};

export default Roles;
