import React from "react";
import Link from "gatsby";

// Sections
import Hero from "../../components/sections/Hero";
import HeroSeparator from "../../components/sections/HeroSeparator";
import Perks from "../../components/sections/Perks";
import Roles from "../../components/sections/Roles";
import Cta from "../../components/sections/Cta";
import Layout from "../../layouts"
// Images
import bgHero from "../../assets/images/bg-jobs.jpg";
import lineImg from "../../assets/images/jobs-icon.svg";

const Jobs = () => {
	return (
		<Layout>
			<Hero hero={bgHero} layout="extended">
				<div className="container h-100 ">
					<div className="hero__inner">
						<h1 className="h1">
							<span>Want to</span>
							<span>join the</span>
							<span>team?</span>
						</h1>
						<div className="hero__content">
							<p>
								We always want to hear from clever, talented, experienced people who are also brilliant to work with. In return, we promise to be a friendly, supportive and exciting
								place to work.
							</p>
							<p>You do your best for us, and we’ll do our best by you.</p>
							<div className="btn-wrapper">
								{/* <Link to="roles" smooth={true} offset={-100} duration={500} className="btn btn-primary">
									View roles
								</Link> */}
							</div>
						</div>
					</div>
				</div>
			</Hero>
			<HeroSeparator image={lineImg} />
			<Perks />
			<Roles id="roles" />
			<Cta />
		</Layout>
	);
};

export default Jobs;
